





















































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  DataDictionaryDtoPagedResultDto,
  FundProjectAccountDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "FundProjectAccountList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
  },
})
export default class FundAccountList extends Vue {
  projectRange: any = [];

  queryForm = {
    fundProjectName: "",
    areaIds: "",
  };

  created() {
    this.fetchDataDictionary();
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "FundProjectArea",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.projectRange = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fundProjectAccount.getAll(params);
  }

  // 拨付明细
  jumpToFundAppropriateRecord(row: FundProjectAccountDto) {
    this.$router.push({
      name: "fundAppropriateRecord",
      query: { projectId: row.fundProjectId!.toString() },
    });
  }

  // 捐赠明细
  jumpToFundIncomeRecord(row: FundProjectAccountDto) {
    this.$router.push({
      name: "fundIncomeRecord",
      query: { projectId: row.fundProjectId!.toString() },
    });
  }
}
